import Vue from 'vue'
import VueRouter from 'vue-router'
import Qr from '../views/Qr.vue'
// import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/:token',
    name: 'Qr',
    component: Qr
  },
  // {
  //   path: '/scan/:id/:name/:lname/:tel/:path',
  //   name: 'Qr',
  //   component: Qr
  // },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import UUID from 'vue-uuid'  
import axios from 'axios'
import VueAxios from 'vue-axios'
import moment from 'moment'
import VueCryptojs from 'vue-cryptojs'

Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).format('MM/DD/YYYY')
  }
})
Vue.filter('formatDateMysql', function(value) {
  if (value) {
    return moment(String(value)).format('YYYY-MM-DD')
  }
})
Vue.filter('formatTime', function(value) {
  if (value) {
    return moment(String(value)).format('hh:mm')
  }
})


Vue.use(VueCryptojs)
Vue.use(VueAxios, axios)
Vue.use(UUID);  
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

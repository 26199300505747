<template>
  <div  class="fullscreen">

    <!-- <div class="mdc-touch-target-wrapper back">
      <button class="mdc-fab mdc-fab--mini mdc-fab--touch" @click="$router.go(-1)">
        <div class="mdc-fab__ripple"></div>
        <span class="material-icons mdc-fab__icon">arrow_back_ios</span>
        <div class="mdc-fab__touch"></div>
      </button>
    </div> -->
    <!-- <p class="decode-result"><b>{{ result }}</b></p> -->


    <qrcode-stream :camera="camera" @decode="onDecode" @init="onInit">
      
      <span class="title" v-if="isLoading" v-show="!showScanConfirmation">
        <span style="position: fixed;bottom: 10px;left: 44%;">
          <img src="/img/icons/phan.png" style="width: 3em;" alt="" srcset="">
        </span>
        <img src="/img/icons/vaccination-1st.png" alt="" srcset="" style="width: 2em;vertical-align: middle;" v-if="!isVaccinated">
        <img src="/img/icons/vaccinated.png" alt="" srcset="" style="width: 2em;vertical-align: middle;" v-if="isVaccinated">
        {{ msg }}{{xerror}}
      </span>
      <div v-show="showScanConfirmation" class="scan-confirmation">
        <div class="lines">
          <div class="line"></div>
          <div class="line"></div>
          <div class="line"></div>
          <div class="line"></div>
          <div class="line"></div>
        </div>
        <div class="area" >
          <ul class="circles">
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
          </ul>
        </div >
        <div class="row1-container">
          <span style="position: absolute;z-index: 1;left: 30px;top: 0px;">
            <img src="/img/icons/phan.png" alt="" srcset="" style="width: 4em;">
          </span>
          <span style="position: absolute;z-index: 1;right: 30px;top: 0px;">
            <img src="/img/icons/laokyc.png" alt="" srcset="" style="width: 4em;">
          </span>
          <div class="box box-down">
            <img src="/img/icons/verified.svg" alt="">
            <h2>ຮ່ວມໃຈຕ້ານໄພໂຄວິດ-19<br><b><span><img src="/img/icons/placeholder.png" alt="" srcset="" style="width: 1.5em;vertical-align: middle;"></span> Check-In</b></h2>
            <p class="date">{{deContent.datetime | formatDate}}</p>
            <p class="time">{{deContent.datetime | formatTime}}</p>
            <div class="shopname">{{shopName}}</div>
          </div>
        </div>
      </div>
    </qrcode-stream>
    
    

    
    <!-- <div class="success-checkmark" v-if="isVaccinated">
      <div class="check-icon">
        <span class="icon-line line-tip"></span>
        <span class="icon-line line-long"></span>
        <div class="icon-circle"></div>
        <div class="icon-fix"></div>
      </div>
    </div> -->

  </div>
</template>

<script>
import { QrcodeStream } from 'vue-qrcode-reader'

import mqtt from 'mqtt'
import { uuid } from 'vue-uuid'
import jwt_decode from "jwt-decode"

import moment from 'moment'

export default {

    props: {
        msg: String
    },

  components: { QrcodeStream },

  data () {
    return {
      isVaccinated: false,
      isLoading: false,
      camera: 'auto',
      result: null,
      showScanConfirmation: false,
      xerror:null,
      connection: {
        host: 'mqtt.laogw.la',
        port: 8084,
        endpoint: '/mqtt', //endpoint
        clean: true, // Reserved session
        connectTimeout: 4000, // Time out
        reconnectPeriod: 4000, // Reconnection interval
        // Certification Information
        clientId: uuid.v1(),
        username: 'phan',
        password: 'Ph@n@2022',
      },
      subscription: {
        topic: 'PASS_AND_PHAN_TOPIC',
        qos: 0,
      },
      publication: {
        topic: 'PASS_AND_PHAN_TOPIC',
        qos: 0,
        payload: '',
      },
      receiveNews: '',
      qosList: [
        { label: 0, value: 0 },
        { label: 1, value: 1 },
        { label: 2, value: 2 },
      ],
      client: {
        connected: false,
      },
      subscribeSuccess: false,
      shopName:'',
      deContent: {
         tel: 0,
         name:'',
         surname:'',
         birthday:'',
         gender:'',
         profileid:'',
         serial:'',
         tags:[],
         shopcode:'',
         personcode:'',
         personid:'',
         datetime: new Date,
      },
      cryptoid:{
        name:'',
        surname:'',
        birthday:'',
        gender:'',
      }
    }
  },

    created() {
        this.laokycConnect()
        this.createConnection()
        this.doSubscribe()
        this.hdid = this.$route.params.id
    },

  methods: {

    async onInit (promise) {
      try {
        await promise
      } catch (e) {
        console.error(e)
      } finally {
        this.showScanConfirmation = this.camera === "off"
        this.isLoading = true
      }
    },

    async onDecode (content) {
      this.result = content
      if (this.result != "") {
        //   let deviceId = content.split('/')[1];
          // console.log(this.result)
        //   this.$http.get('http://10.2.100.147:1324/api/v1/shop_device_info/'+deviceId).then((response) => {
        //         console.log(response.data.data[0])
        //     });
        this.deContent.result = this.result
        this.deContent.datetime = new Date()
        // http://103.232.83.116:1323/api/v1/shop_device_info
        // console.log(this.result.split('/')[1])
        // console.log(this.result.split('/')[2])
        // console.log(this.result)
        // this.axios.get(`https://phan-api.laogw.la/api/v1/shop_device_info/${this.result.split('/')[1]}`)
        // .then((res) => {
        //   const data = res.data.data[0]
        //   // console.log(data)
          this.shopName = this.result.split('/')[2]
          this.deContent.shopcode = this.result
          this.doPublish(JSON.stringify(this.deContent))
          // setTimeout(() => this.$router.go(this.$router.currentRoute), 5500);
        // })
        // .catch((error) => {
        //   console.error(error)
        // })
        // let jxson = `{"shopCode": "`+this.result+`","phoneNumber": "`+this.$route.params.tel+`","firstName": "`+this.$route.params.name+" "+this.$route.params.lname+`","personCode": "`+this.$route.params.id+`"}`
        // console.log(jxson)

        
      }
      this.pause()
      // await this.timeout(3000)
      // this.unpause()
    },

    unpause () {
      this.camera = 'auto'
    },

    pause () {
      this.camera = 'off'
    },

    timeout (ms) {
      return new Promise(resolve => {
        window.setTimeout(resolve, ms)
      })
    },
    // Create connection
    createConnection() {
      // Connect string, and specify the connection method used through protocol
      // ws unencrypted WebSocket connection
      // wss encrypted WebSocket connection
      // mqtt unencrypted TCP connection
      // mqtts encrypted TCP connection
      // wxs WeChat mini app connection
      // alis Alipay mini app connection
      const { host, port, endpoint, ...options } = this.connection
      const connectUrl = `wss://${host}:${port}${endpoint}`
      try {
        this.client = mqtt.connect(connectUrl, options)
      } catch (error) {
        this.xerror = error;
        console.log('mqtt.connect error', error)
      }
      this.client.on('connect', () => {
        console.log('Connection succeeded!')
      })
      this.client.on('error', error => {
        this.xerror = error;
        console.log('Connection failed', error)
      })
      this.client.on('message', (topic, message) => {
        this.receiveNews = this.receiveNews.concat(message)
        console.log(`Received message !!`)
        // console.log(`Received message !!tp: ${topic} ms:${message}`)
      })
    },
    doSubscribe() {
      const { topic, qos } = this.subscription
      this.client.subscribe(topic, { qos }, (error) => {
        if (error) {
          this.xerror = error;
          console.log('Subscribe to topics error', error)
          return
        }
        this.subscribeSuccess = true
        // console.log('Subscribe to topics res', res)
        })
    },
    doUnSubscribe() {
      const { topic } = this.subscription
      this.client.unsubscribe(topic, error => {
        if (error) {
          this.xerror = error;
          console.log('Unsubscribe error', error)
        }
      })
    },
    doPublish(val) {
      this.publication.payload = val
      const { topic, qos, payload } = this.publication
      this.client.publish(topic, payload, qos, error => {
        if (error) {
          this.xerror = error;
          console.log('Publish error', error)
        }else{
          console.log('Publish Successfully')
          this.active = true
          // this.$router.go(-1)
        }
      })
    },
    destroyConnection() {
      if (this.client.connected) {
        try {
          this.client.end()
          this.client = {
            connected: false,
          }
          console.log('Successfully disconnected!')
        } catch (error) {
          this.xerror = error;
          console.log('Disconnect failed', error.toString())
        }
      }
    },
    async laokycConnect(){

      const index = '/index.html?'
      const access_token = this.$route.fullPath.substring(index.length,this.$route.fullPath.length)
      console.log(access_token)
      var decodedHeader = jwt_decode(access_token);
      this.deContent.tel = decodedHeader.preferred_username
      const self = this
      this.axios.get('https://api.laokyc.la/api/v1/UserProfile/getprofile', {
        headers: {
          'Authorization': `bearer ${access_token}`
        }
      })
      .then((res) => {
        if (res.data.isSuccess == true) {
           console.log(res.data.profile)
          const profile = res.data.profile
          self.axios.get(`https://api.laokyc.la/api/v1/Identity/cardstack/${res.data.profile.id}`, {
            headers: {
              'Authorization': `bearer ${access_token}`
            }
          })
          .then((res) => {
            const data = res.data
             console.log(data)
            if (data.isSuccess == true) {
              if (data.contents.length > 0) {
                for (let index = 0; index < data.contents.length; index++) {
                  const el = data.contents[index];
                  if (el.card.cardType === "ບັດຢັ້ງຢືນການຮັບກັນພະຍາດໂຄວິດ-19") {
                    const tags = el.myIdentity.tags
                    // self.deContent.tags = tags
                    self.deContent.name = el.myIdentity.name
                    self.deContent.surname = el.myIdentity.surname
                    self.deContent.profileid = el.myIdentity.profileID
                    self.deContent.birthday = profile.birthday
                    self.deContent.gender = el.myIdentity.gender
                    self.deContent.serial = el.myIdentity.serial
                    if (tags.length > 0) {
                      if (tags[0].name == "isonedose1") {
                        if (tags[0].value == "false") {
                          self.isVaccinated = true
                          // console.log(self.deContent)
                        }
                      }
                      let arr = []
                      let index = 0
                      for (let i = 1; i < tags.length; i++) {
                        const item = tags[i];
                        
                        if (index == parseInt(item.name.replace(/\D/g, ""),16)) {
                          // console.log(parseInt(item.name.replace(/\D/g, ""),16))
                          if (item.name.includes("productname")) {
                            arr[index-1].productname = item.value
                          }
                          if (item.name.includes("date")) {
                            arr[index-1].date = item.value
                          }
                        }else{
                          // console.log(parseInt(item.name.replace(/\D/g, ""),16))
                          if (item.name.includes("productname")) {
                             arr.push({
                              productname: item.value,
                              date: null
                            })
                          }
                         
                        }
                        index = parseInt(item.name.replace(/\D/g, ""),16)
                      }
                      self.deContent.tags = arr
                    }
                  }
                }
              }
            }
            
          })
          .catch((error) => {
            console.error(error)
          })
          .finally(() =>{
            // console.log(self.deContent)
            self.cryptoid.name = self.deContent.name
            self.cryptoid.surname = self.deContent.surname
            self.cryptoid.birthday = self.deContent.birthday
            self.cryptoid.gender = self.deContent.gender
            var md5 = require('md5')
            self.deContent.personcode = md5(JSON.stringify(self.cryptoid))
            self.phanConnect()
          })
        }
      })
      .catch((error) => {
        console.error(error)
      })
    },
    phanConnect(){
      let d = moment(this.deContent.birthday, 'DD-MM-YYYY').format('YYYY-MM-DD')
      // console.log(this.deContent.birthday)
      // console.log(d)
      this.axios.get(`https://phan-api.laogw.la/api/v1/personal/kyc?f=${this.deContent.name}&l=${this.deContent.surname}&p=${this.deContent.tel}&g=${this.deContent.gender.toLowerCase()}&d=${d}`, {
      })
      .then((res) => {
        if (res.data.data != null) {
          this.deContent.personid = res.data.data.person_id
        }
        // console.log(this.deContent)
      })

    }
  }
}
</script>
<style lang="sass">
@import url(https://fonts.googleapis.com/earlyaccess/phetsarath.css)
*
  font-family: 'Phetsarath', sans-serif

.lines
  position: absolute
  top: 0
  left: 0
  right: 0
  height: 100%
  margin: auto
  width: 90vw
  
.line
  position: absolute
  width: 1px
  height: 100%
  top: 0
  left: 50%
  background: transparent
  overflow: hidden

  &::after
    content: ''
    display: block
    position: absolute
    height: 15vh
    width: 100%
    top: -50%
    left: 0
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgb(255 255 255 / 87%) 75%, rgb(255 255 255 / 70%) 100%)
    animation: drop 7s 0s infinite
    animation-fill-mode: forwards
    animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97)
  
  &:nth-child(1)
    margin-left: -20%
    &::after
      animation-delay: 1.5s
  
  &:nth-child(3)
    margin-left: 20%
    &::after
      animation-delay: 2s

  &:nth-child(4)
    margin-left: -40%
    &::after
      animation-delay: 2.5s

  &:nth-child(5)
    margin-left: 40%
    &::after
      animation-delay: 3s
    
@keyframes drop
  0%
    top: -50%
  100%
    top: 110%

</style>

<style scoped lang="scss">
.fullscreen {
  position: fixed;
  z-index: 1000;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  .title{
    position: absolute;
    z-index: 1;
    bottom: 15%;
    font-size: large;
    margin: 0;
    color: #fff;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
  }
  .mdc-touch-target-wrapper.back{
    position:absolute;
    z-index: 1;
    top:10px;
    left: 20px;
    .mdc-fab{
      background-color: aliceblue;
      .material-icons{
        color: #212121;
        margin-left: 10px;
      }
    }
  }
}

.scan-confirmation {
  position: relative;
  width: 100%;
  height: 100%;
  display: block;
  div.contents{
    position: absolute;
    width: 80%;
    top:50%;
    height: 200px;
    margin: 0;
    margin-left: auto;
    margin-right: auto;
  }
}

.area{
    background: #1b8aed;  
    background: -webkit-linear-gradient(to left, #1b8beda4, #1b8aed);  
    width: 100%;
    height:100vh;
    
   
}

.circles{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    margin: 0;
}

.circles li{
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: rgba(255, 255, 255, 0.2);
    animation: animate 25s linear infinite;
    bottom: -150px;
    
}

.circles li:nth-child(1){
    left: 25%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
}


.circles li:nth-child(2){
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 2s;
    animation-duration: 12s;
}

.circles li:nth-child(3){
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
}

.circles li:nth-child(4){
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 18s;
}

.circles li:nth-child(5){
    left: 65%;
    width: 20px;
    height: 20px;
    animation-delay: 0s;
}

.circles li:nth-child(6){
    left: 75%;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
}

.circles li:nth-child(7){
    left: 35%;
    width: 150px;
    height: 150px;
    animation-delay: 7s;
}

.circles li:nth-child(8){
    left: 50%;
    width: 25px;
    height: 25px;
    animation-delay: 15s;
    animation-duration: 45s;
}

.circles li:nth-child(9){
    left: 20%;
    width: 15px;
    height: 15px;
    animation-delay: 2s;
    animation-duration: 35s;
}

.circles li:nth-child(10){
    left: 85%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
    animation-duration: 11s;
}
.circles li:nth-child(11){
    left: 35%;
    width: 50px;
    height: 50px;
    animation-delay: 0s;
    animation-duration: 15s;
}



@keyframes animate {

    0%{
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }

    100%{
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 100%;
    }

}

.box {
  border-radius: 5px;
  box-shadow: 0px 30px 40px -20px rgb(0 0 0 / 38%);
  padding: 20px;
  margin: 20px;  
  background-color: #ffffffe0;
  top: 0;
  img {
      width: 65px;
  }
  h2{
    margin-top: 0;
  }
  p{
    color: #000;
    margin: 0;
  } 
  .date {
    font-size: x-large;
    font-weight: 900;
  }
  .time {
    font-size: xx-large;
    font-weight: 900;
    margin-top: 10px;
    margin-bottom: 10px;
    color: #1c8aed !important;
  }
  .shopname {
    font-size: x-large;
    font-weight:bold;
    word-wrap: break-word;
    overflow: auto;
    max-height: 90px;
  }
}


@media (max-width: 450px) {
    .box {
        min-width: 200px;
    }
    .row1-container {
        top: 20% !important;
    }
}

@media (max-width: 950px) and (min-width: 450px) {
  .fullscreen .title{
    bottom: 20%;
  }
    .box {
        text-align: center;
        min-width: 200px;
    }
    .row1-container {
        top: 0% !important;
    }
}


.row1-container {
  position: absolute;
  top: 25%;
  width: 100%;
}
@media (min-width: 950px) {
    .row1-container {
        display: flex;
        justify-content: center;
        align-items: center;
        top: 15% !important;
    }

    .box-down {
        position: relative;
        top: 150px;
    }
    .box {
        width: 80%;
     
    }
    
}

/**
 * Extracted from: SweetAlert
 * Modified by: Istiak Tridip
 */
// .success-checkmark {
//     width: 80px;
//     margin: 0 auto;
//     position: absolute;
//     top: 85%;
//     margin-left: auto;
//     margin-right: auto;
//     left: 0;
//     right: 0;
    
//     .check-icon {
//         width: 80px;
//         height: 80px;
//         position: relative;
//         border-radius: 50%;
//         box-sizing: content-box;
//         border: 4px solid #4CAF50;
        
//         &::before {
//             top: 3px;
//             left: -2px;
//             width: 30px;
//             transform-origin: 100% 50%;
//             border-radius: 100px 0 0 100px;
//         }
        
//         &::after {
//             top: 0;
//             left: 30px;
//             width: 60px;
//             transform-origin: 0 50%;
//             border-radius: 0 100px 100px 0;
//             animation: rotate-circle 4.25s ease-in;
//         }
        
//         &::before, &::after {
//             content: '';
//             height: 100px;
//             position: absolute;
//             background: transparent;
//             transform: rotate(-45deg);
//         }
        
//         .icon-line {
//             height: 5px;
//             background-color: #4CAF50;
//             display: block;
//             border-radius: 2px;
//             position: absolute;
//             z-index: 10;
            
//             &.line-tip {
//                 top: 46px;
//                 left: 14px;
//                 width: 25px;
//                 transform: rotate(45deg);
//                 animation: icon-line-tip 0.75s;
//             }
            
//             &.line-long {
//                 top: 38px;
//                 right: 8px;
//                 width: 47px;
//                 transform: rotate(-45deg);
//                 animation: icon-line-long 0.75s;
//             }
//         }
        
//         .icon-circle {
//             top: -4px;
//             left: -4px;
//             z-index: 10;
//             width: 80px;
//             height: 80px;
//             border-radius: 50%;
//             position: absolute;
//             box-sizing: content-box;
//             border: 4px solid rgba(76, 175, 80, .5);
//         }
        
//         .icon-fix {
//             top: 8px;
//             width: 5px;
//             left: 26px;
//             z-index: 1;
//             height: 85px;
//             position: absolute;
//             transform: rotate(-45deg);
//             background-color: transparent;
//         }
//     }
// }

// @keyframes rotate-circle {
//     0% {
//         transform: rotate(-45deg);
//     }
//     5% {
//         transform: rotate(-45deg);
//     }
//     12% {
//         transform: rotate(-405deg);
//     }
//     100% {
//         transform: rotate(-405deg);
//     }
// }

// @keyframes icon-line-tip {
//     0% {
//         width: 0;
//         left: 1px;
//         top: 19px;
//     }
//     54% {
//         width: 0;
//         left: 1px;
//         top: 19px;
//     }
//     70% {
//         width: 50px;
//         left: -8px;
//         top: 37px;
//     }
//     84% {
//         width: 17px;
//         left: 21px;
//         top: 48px;
//     }
//     100% {
//         width: 25px;
//         left: 14px;
//         top: 45px;
//     }
// }

// @keyframes icon-line-long {
//     0% {
//         width: 0;
//         right: 46px;
//         top: 54px;
//     }
//     65% {
//         width: 0;
//         right: 46px;
//         top: 54px;
//     }
//     84% {
//         width: 55px;
//         right: 0px;
//         top: 35px;
//     }
//     100% {
//         width: 47px;
//         right: 8px;
//         top: 38px;
//     }
// }
</style>
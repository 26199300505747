<template>
  <div class="home">
    <!-- <img alt="Vue logo" src="../assets/logo.png"> -->
    <qr msg="Scan QR code PHAN"/>
  </div>
</template>

<script>
// @ is an alias to /src
import Qr from '@/components/QrX.vue'

export default {
  name: 'Home',
  components: {
    Qr
  }
}
</script>
